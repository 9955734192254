'use client'

import { useReportWebVitals } from 'next/web-vitals'

const WebVitals = () => {
  useReportWebVitals((metric) => {
    if (process.env.CF_ENVIRONMENT !== 'master') {
      console.log(metric)
    }
  })
  return null
}

export default WebVitals
