import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-montserrat\",\"adjustFontFallback\":false}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/chat-analytics-tracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/foundation-newsletter-signup.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/newsletter-signup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/web-vitals.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/main.css");
